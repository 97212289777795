import React from "react";
import Logo from "../Images/Untitled design (3).png"
import useWindowDimensions from "../useWindowDimensions";
export const Header = (props) => {
  const { width } = useWindowDimensions();

  return (
    <header id="header">
      <div className="">
        
          
           
            <img src={Logo} height={width<500?"120%":"100%"} width={width<500?"160%":"100%"} style={{marginTop:width<500?"30%":"8%",}} ></img>
              {/* <div className="col-md-8 col-md-offset-1 intro-text" style={{marginTop:300}}>
              </div> */}
           
      
      
      </div>
    </header>
  );
};
