import React from "react";

import imgage from "../Images/smdc-removebg-preview (1).png"

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top" 
    style={{borderBottomWidth:"1.5px",borderBottomColor:"#c2201f",borderBottomStyle:"solid"}}
    >
      <div className="container"
       >
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            // data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            style={{color:"#c2201f"}}
          >
         
            <span style={{color:"#c2201f"}} className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
          <img src={imgage} width={105} height={105} style={{marginTop:-50}}></img>
          

          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
          {/* <li>
              <a href="#features" className="page-scroll" style={{color:"#293788",fontWeight:"bold"}}>
                Features
              </a>
            </li> */}
            {/* <li>
              <a href="#features" className="page-scroll" style={{color:"#293788",fontWeight:"bold"}}>
                Features
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll" style={{color:"#293788",fontWeight:"bold"}}>
                About
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll" style={{color:"#293788",fontWeight:"bold"}}>
                Services
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll" style={{color:"#293788",fontWeight:"bold"}}>
                Gallery
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll" style={{color:"#293788",fontWeight:"bold"}}>
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll" style={{color:"#293788",fontWeight:"bold"}}>
                Team
              </a>
            </li> */}
            <li>
              <a href="#footer" className="page-scroll" style={{color:"#293788",fontWeight:"bold"}}>
                Contact US
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
