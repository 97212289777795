import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    emailjs
      .sendForm("service_7t8klg7", "template_meelln8", e.target, "cX6Bmq-khcKFe5z_p")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          alert("Thank you for contacting us !  we will get back to you as soon as possible ")
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      {/* <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item" style={{color:"white"}}>
              <h3>Contact Info</h3>
              <p style={{color:"white"}}>

                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
              
                Plot No. 314 & 315, Street A-1, Main PWD Rd, Police Foundation, Islamabad
              </p>
            </div>
            <div className="contact-item" style={{color:"white"}}>
              <p style={{color:"white"}}>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>
            
                051-6128511 / 0336-9406653

              </p>
            </div>
            <div className="contact-item" style={{color:"white"}} >
              <p style={{color:"white"}}>
                <span >
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                info@khatiba.pk
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      
      <div id="footer" style={{background:"WHITE",
      // borderTop:"1.5px solid #c2201f"
      }}>
        <div className="container text-center" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
          <p style={{color:"#104f9f",textAlign:"center",textAlign:"center"}}>
           copyright <span style={{color:"#c2201f"}}> &copy; 2024</span> Saeed Medical & Diagnostic Center. 
            
          </p>
        </div>
      </div>
    </div>
  );
};
